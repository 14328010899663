<template>
  <div>
    <table-wrapper
      :table-header="headers"
      noFilters
      :change-page-size="changePageSize"
      :fetch-data="fetchData"
      :pager="pager"
      :rows="rows"
      :list-length="userPlafond.length"
      :is-loading="isLoading"
    >
      <template #body>
        <user-plafond-row
          v-for="plafond in userPlafond"
          :key="plafond.plafondId"
          :user-plafond="plafond"
        />
      </template>
      <template #noResults>
        <div>
          <loader-info v-if="isLoading" />
          <div v-else>
            <p class="mb-4">
              <em>
                <strong>
                  Nessun plafond disponibile
                </strong>
              </em>
            </p>
          </div>
        </div>
      </template>
      <template #actions>
        <new-plafond
          entity-class="PLAFOND"
          :on-success="onSuccessAdd"
        />
      </template>
    </table-wrapper>
  </div>
</template>

<script>
import { extractErrorMessage, isNotEmpty, isNotExist } from '@/utils/validators';

const LoaderInfo = () => import('@/components/helpers/LoaderInfo.vue');
const UserPlafondRow = () => import('./UserPlafondRow.vue');
const TableWrapper = () => import('@/components/tableWrapper/TableWrapper.vue');
const NewPlafond = () => import('@/components/userPlafond/NewPlafond.vue');

export default {
  name: 'UserPlafondTable',
  components: {
    LoaderInfo,
    UserPlafondRow,
    TableWrapper,
    NewPlafond,
  },
  props: {
    completeData: Boolean,
  },
  data() {
    return {
      searchBase: {
        search: '',
        page: 0,
        size: 10,
        sort: [{ field: 'lastModified', direction: 'DESC' }],
        filters: [],
      },
      entityType: null,
      entityId: null,
      submitted: false,
      note: null,
      customFilters: false,
      // listKey: moment().format(),
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters['invoice/isLoading'];
    },
    pager() {
      return this.$store.getters['invoice/plafondListPageable'];
    },
    rows() {
      const paging = this.pager;
      return paging.totalElements;
    },
    userPlafond() {
      return this.$store.getters['invoice/plafondList'];
    },
    headers() {
      return [
        {
          classes: 'white-space-nowrap',
          content: 'Banca',
        },
        {
          classes: 'white-space-nowrap',
          content: 'Tipologia',
        },
        {
          classes: 'white-space-nowrap',
          content: 'Accordato',
        },
        {
          classes: 'white-space-nowrap',
          content: 'In uso',
        },
        {
          classes: 'white-space-nowrap',
          content: 'Data inizio',
        },
        {
          classes: 'white-space-nowrap',
          content: 'Data fine',
        },
        // {
        //   classes: 'table-cell--compact',
        //   content: '',
        // },
      ];
    },
  },
  watch: {
    completeData(val, oldVal) {
      // console.log('watch completeData', val, oldVal);
      if (val !== oldVal && val) this.fetchData(true);
    },
  },
  mounted() {
    // console.log('list created');
    this.fetchData(true);
  },
  methods: {
    fetchData(reload, nextPage) {
      // console.log('list fectad', this.searchBase, nextPage);
      const currentProfile = this.$store.getters['subject/currentCompany'];
      if (isNotEmpty(currentProfile)) {
        this.entityId = currentProfile.companyId;
        this.entityType = 'company';
      } else {
        this.entityType = 'person';
        this.entityId = this.$store.getters['subject/currentPerson']?.personId;
      }

      if (isNotExist(this.entityId)) return;

      const search = { ...this.searchBase, companyId: this.entityId };

      // console.log('fetch', nextPage);
      if (isNotEmpty(nextPage)) {
        const paging = this.$store.getters['invoice/plafondListPageable'];
        if (nextPage > 0 && nextPage <= paging.totalPages) {
          search.page = nextPage - 1;
        }
      }
      // console.log('this.entityId', this.entityId);
      this.$store.dispatch('invoice/loadPlafondsList', {
        search,
        reset: reload,
      })
        .then(() => {
          this.submitted = false;
          this.searchBase.page = search.page;
        },
        (error) => {
          this.message = extractErrorMessage(error);
          this.submitted = false;
        });
    },
    routeTo(path) {
      this.$router.replace(path);
    },
    changePageSize(size) {
      // console.log('pageSizeSelected', this.pageSizeSelected, size);
      this.searchBase.size = size;
      this.customFilters = true;
      this.fetchData(true);
    },
    onSuccessAdd() {
      // this.listKey = moment().format();
      this.fetchData(true);
    },
  },
};
</script>

<style scoped>

</style>
